import React, { useState } from "react";
import "./About.css"
import { stackList } from "../../data/ProjectData";
import {
  Image,
  Technologies,
  Tech,
  TechImg,
  TechName,
  ContactWrapper,
} from "./AboutElements";
import ScrollAnimation from "react-animate-on-scroll";

function About() {
  const [showFullContent, setShowFullContent] = useState(false);

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  return (
    <>
    <svg
        height="100%"
        width="100%"
        id="svg"
        viewBox="0 0 1440 400"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M 0,400 C 0,400 0,200 0,200 C 114.35714285714289,156.53571428571428 228.71428571428578,113.07142857142858 351,131 C 473.2857142857142,148.92857142857142 603.4999999999998,228.25 713,248 C 822.5000000000002,267.75 911.2857142857144,227.92857142857142 1029,210 C 1146.7142857142856,192.07142857142858 1293.3571428571427,196.03571428571428 1440,200 C 1440,200 1440,400 1440,400 Z"
          stroke="none"
          strokeWidth="0"
          fill="#151418ff"
          transform="rotate(-180 720 200)"
        ></path>
      </svg>
    <ContactWrapper id="about">
      
      <div className="Container">
      
        <div className="SectionTitle">About Me</div>
        <div className="BigCard">
          <ScrollAnimation animateIn="fadeInLeft">
            <Image src="/coding.svg" alt="man-svgrepo" />
          </ScrollAnimation>
          <div className="AboutBio">
            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                Greetings! I'm <strong>Bidyasagar Hazarika,</strong> an
                independent Web Developer hailing from Guwahati, India.
                Currently pursuing my B.Tech in Electronics and Communication
                Engineering at Assam Engineering College, Guwahati.
              </p>
            </ScrollAnimation>

            <br />

            {showFullContent ? (
              <>
                <ScrollAnimation animateIn="fadeInRight">
                  <p>
                    I am an independent Web Developer with expertise in the
                    MERN stack. If you are seeking additional customization for
                    your website or require professional initial setup, I am
                    well-equipped for the task. Over the past year, I have been
                    dedicated to creating websites tailored to businesses of
                    various sizes. Regardless of the project's scale, I
                    specialize in providing consulting services to help you
                    establish and design your website. Subsequently, I offer
                    comprehensive training and facilitate a smooth handover,
                    empowering you to manage your site with minimal ongoing
                    support. Furthermore, I am available for recurring
                    maintenance tasks to keep your website up-to-date, and I
                    also offer personalized one-on-one training sessions for
                    your convenience.
                  </p>
                </ScrollAnimation>

                <br />
              </>
            ) : null}

            <ScrollAnimation animateIn="fadeInLeft">
            <button onClick={toggleContent} className="ReadMoreButton">
              {showFullContent ? "Read Less" : "Read More"}
                
              </button>
              
            </ScrollAnimation>

            <br />

            <ScrollAnimation animateIn="fadeInLeft">
              <div className="tagline2">
                I have become confident using the following technologies:
              </div>
            </ScrollAnimation>

            <Technologies>
              {stackList.map((stack, index) => (
                <ScrollAnimation animateIn="fadeInLeft" key={index}>
                  <Tech key={index} className="tech">
                    <TechImg src={stack.img} alt={stack.name} />
                    <TechName>{stack.name}</TechName>
                  </Tech>
                </ScrollAnimation>
              ))}
            </Technologies>
          </div>
        </div>
      </div>
    </ContactWrapper>
    </>
  );
}

export default About;
