import React from "react";
import "./App.css";
import Preloader from "./components/Preloader/Preloader"
import Home from "./pages/Home";

function App() {
  

  return (
    <>
   <Preloader/>
      <Home />
    </>
  );
}

export default App;
