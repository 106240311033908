// --------------------------------------- Project List
export const ProjectList = [
  // {
  //   img: "/newpresto.png",
  //   title: "Presto Clean",
  //   description:
  //     "A laundry service application",
  //   tech_stack: ["React", "MongoDB","NodeJS","Express"],
  //   github_url: "",
  //   demo_url: "https://prestoclean.in",
  // },
  {
    img: "/roboclub-website.png",
    title: "Robotics Club-AEC",
    description: "Official Website of Robotics club Assam Engineering College",
    tech_stack: ["React"],
    github_url: "",
    demo_url: "https://roboclub.aec.ac.in",
  },
  {
    img: "/Gigmedia_webview.png",
    title: "Gig-Media",
    description: "Social media website",
    tech_stack: ["React","Vite","Firebase","Chakra UI"],
    github_url: "https://github.com/DevDreamer26/Gig-media",
    demo_url: "https://gig-media.netlify.app/",
  },
  {
    img: "/gig.png",
    title: "GigCrafters",
    description: "My freelancing site",
    tech_stack: ["HTML", "CSS","JavaScript"],
    github_url: "",
    demo_url: "https://gigcrafters.netlify.app/",
  },
  {
    img: "/schoolwebsite.png",
    title: "SSVN-Golaghat",
    description: "Website of SSVN-Golaghat",
    tech_stack: ["React"],
    github_url: "",
    demo_url: "https://ssvnglt.netlify.app/",
  },
  {
    img: "/tab.png",
    title: "TabTracker",
    description: "A chrome extension to save tabs in browser.",
    tech_stack: ["JavaScript"],
    github_url: "https://github.com/DevDreamer26/Tab-Tracker",
    demo_url: "",
  },
  {
    img: "/bidyasagar.jpeg",
    title: "Home",
    description: "Another portfolio website",
    tech_stack: ["MERN"],
    github_url: "",
    demo_url: "https://bidyasagar-rjs.netlify.app/",
  },
  
];

// --------------------------------------- Skills

export const stackList = [
  {
    img: "icons8-html.svg",
    name: "HTML",
  },
  {
    img: "icons8-css.svg",
    name: "CSS",
  },
  {
    img: "icons8-js.svg",
    name: "JavaScript",
  },
  {
    img: "icons8-react-js.svg",
    name: "ReactJS",
  },
  // {
  //   img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nextjs/nextjs-original.svg",
  //   name: "NextJs",
  // },
  // {
  //   img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg",
  //   name: "Typescript",
  // },
  {
    img: "icons8-git.svg",
    name: "Git",
  },
  // {
  //   img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
  //   name: "React Native",
  // },
  {
    img: "icons8-nodejs.svg",
    name: "NodeJS",
  },
  {
    img: "icons8-github.svg",
    name: "Github",
  },
  {
    img: "mongodb.svg",
    name: "MongoDB",
  },
  // {
  //   img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
  //   name: "Python",
  // },
  // {
  //   img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/flask/flask-original.svg",
  //   name: "Flask",
  // },
  // {
  //   img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/c/c-original.svg",
  //   name: "C",
  // },
  // {
  //   img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg",
  //   name: "Java",
  // },
  {
    img: "icons8-express-js.svg",
    name: "Express-JS",
  },
  {
    img: "bootstrap.svg",
    name: "Bootstrap",
  },
  {
    img: "mui.png",
    name: "Material UI",
  },
  {
    img: "icons8-sass.svg",
    name: "Sass",
  },
  {
    img: "postman.svg",
    name: "Postman",
  },
  {
    img: "icons8-linux.gif",
    name: "Linux",
  },
  {
    img:"chakra.png",
    name:"Chakra UI"
  },
  {
    img:"firebase_preview.png",
    name:"Firebase"
  }
];
