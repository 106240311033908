import React, { useEffect } from 'react'
import { preLoaderAnim } from '../../animations/Animation';
import "./preloader.css"
const Preloader = () => {

    useEffect(()=>{
        preLoaderAnim()
    },[]);


  return (
    <div className='preloader'>
        <div className='text-container'>
        {/* <span>Hello</span> */}
        {/* <span>01010111 01100101 01101100 01100011 01101111 01101101 01100101</span> */}
        <span>WELCOME</span>
       

        

       
        </div>
    </div>
  )
}

export default Preloader