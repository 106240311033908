import React from "react";
import ProjectCard from "./ProjectCard/ProjectCard";
function Projects() {
  return (
    <>
      <svg
        height="100%"
        width="100%"
        id="svg"
        viewBox="0 0 1440 400"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M 0,400 C 0,400 0,200 0,200 C 114.35714285714289,156.53571428571428 228.71428571428578,113.07142857142858 351,131 C 473.2857142857142,148.92857142857142 603.4999999999998,228.25 713,248 C 822.5000000000002,267.75 911.2857142857144,227.92857142857142 1029,210 C 1146.7142857142856,192.07142857142858 1293.3571428571427,196.03571428571428 1440,200 C 1440,200 1440,400 1440,400 Z"
          stroke="none"
          strokeWidth="0"
          fill="#151418ff"
          transform="rotate(-180 720 200)"
        ></path>
      </svg>
     {/* <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 1200 800'><rect fill='#000000' width='1200' height='800'/><defs><radialGradient id='a' cx='0' cy='800' r='800' gradientUnits='userSpaceOnUse'><stop  offset='0' stop-color='#808080'/><stop  offset='1' stop-color='#808080' stop-opacity='0'/></radialGradient><radialGradient id='b' cx='1200' cy='800' r='800' gradientUnits='userSpaceOnUse'><stop  offset='0' stop-color='#171717'/><stop  offset='1' stop-color='#171717' stop-opacity='0'/></radialGradient><radialGradient id='c' cx='600' cy='0' r='600' gradientUnits='userSpaceOnUse'><stop  offset='0' stop-color='#969696'/><stop  offset='1' stop-color='#969696' stop-opacity='0'/></radialGradient><radialGradient id='d' cx='600' cy='800' r='600' gradientUnits='userSpaceOnUse'><stop  offset='0' stop-color='#000000'/><stop  offset='1' stop-color='#000000' stop-opacity='0'/></radialGradient><radialGradient id='e' cx='0' cy='0' r='800' gradientUnits='userSpaceOnUse'><stop  offset='0' stop-color='#FFFFFF'/><stop  offset='1' stop-color='#FFFFFF' stop-opacity='0'/></radialGradient><radialGradient id='f' cx='1200' cy='0' r='800' gradientUnits='userSpaceOnUse'><stop  offset='0' stop-color='#2D2D2D'/><stop  offset='1' stop-color='#2D2D2D' stop-opacity='0'/></radialGradient></defs><rect fill='url(#a)' width='1200' height='800'/><rect fill='url(#b)' width='1200' height='800'/><rect fill='url(#c)' width='1200' height='800'/><rect fill='url(#d)' width='1200' height='800'/><rect fill='url(#e)' width='1200' height='800'/><rect fill='url(#f)' width='1200' height='800'/></svg> */}
<br></br>
      <div className="ProjectWrapper" id="projects">
        <div className="Container">
          <div className="SectionTitle"> My Projects</div>
          <ProjectCard />
        </div>
      </div>
    </>
  );
}

export default Projects;
